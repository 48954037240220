<template>
  <div class="dashboard">
    <div class="container">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Notifications</h4>
          <ul class="notifications">
            <li v-for="(notification, i) in notifications" :key="`notification-${i}`">
              <div :class="`notification ${notification.read ? '' : 'unread'}`">
                <router-link :to="notification.link">
                  <img src="/app.png" alt="app" height="30px" class="mr-2">
                  {{ notification.content }}
                  <span class="badge badge-secondary float-right" v-if="! notification.read">new</span>
                </router-link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      notifications: [],
    };
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get("/api/v1/notifications").then((response) => {
        this.notifications = response.data;
        this.$loader.stop()
      });
    },
  },
};
</script>
